import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import AboutStyle4 from "../About/AboutStyle4";
import FunFact from "../FunFact";
import AboutStyle5 from "../About/AboutStyle5";
import IconBoxStyle6 from "../IconBox/IconBoxStyle6";
import SectionHeadingStyle5 from "../SectionHeading/SectionHeadingStyle5";
import SectionHeading from "../SectionHeading";
import TeamSlider from "../Slider/TeamSlider";
import Marquee from "../Marquee";
import Brands from "../Brands";
import { pageTitle } from "../../helpers/PageTitle";
const funfactData = [
  { title: "Años de experiencia", number: "+18" },
  { title: "Usuarios en nuestra comunidad", number: "+30k" },
  { title: "Asistentes a eventos", number: "+800k" },
  // { title: 'Most Valuable Awards', number: '15' },
];
const teamData = [
  {
    memberImg: "/images/studio-agency/team_1.jpeg",
    memberName: "James Berline",
    memberDesignation: "React Developer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_2.jpeg",
    memberName: "Bella Zubena",
    memberDesignation: "Graphic Designer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_3.jpeg",
    memberName: "Kemnei Alekzend",
    memberDesignation: "Digital Marketer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_4.jpeg",
    memberName: "Juliya Jesmine",
    memberDesignation: "UX Researcher",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_1.jpeg",
    memberName: "James Berline",
    memberDesignation: "React Developer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_2.jpeg",
    memberName: "Bella Zubena",
    memberDesignation: "Graphic Designer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_3.jpeg",
    memberName: "Kemnei Alekzend",
    memberDesignation: "Digital Marketer",
    href: "/team/team-details",
  },
  {
    memberImg: "/images/studio-agency/team_4.jpeg",
    memberName: "Juliya Jesmine",
    memberDesignation: "UX Researcher",
    href: "/team/team-details",
  },
];
const brandList = [
  {
    logoSrc: "/images/marketing-agency/brand_1.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_2.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_3.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_4.svg",
    logoAlt: "Brand",
  },
];
const brandListDark = [
  {
    logoSrc: "/images/marketing-agency/brand_1_dark.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_2_dark.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_3_dark.svg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/marketing-agency/brand_4_dark.svg",
    logoAlt: "Brand",
  },
];

export default function AboutPage({ darkMode }) {
  pageTitle("Sobre nosotros");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title="Somos equipo multidisciplinar con alcance global"
        subTitle=""
        variant="text-center"
        shape="shape_1"
      />
      <Spacing lg="75" md="60" />
      {/* <AboutStyle4
        thumbnailSrc="/images/digital-agency/about_1.jpeg"
        miniTitle="Company Info"
        title="Marketing agency for your business"
        subTitle="Our team, specializing in strategic digital marketing, partners with aiming the world's leading brands. Breaking from the norm, we push boundaries and do merge imaginative thinking posible.
        dolores eos qui ratione voluptatem lipe sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam ever the world lorem ipsum."
        btnText="See Our Services"
        btnUrl="/service"
      /> */}

      <section>
        <div
          className="container"
          style={{ textAlign: "center", fontSize: "28px" }}
        >
          <p>
            A1M es una compañía global con base tecnológica dedicada a la
            industria del entretenimiento moderno asociada en el sector del
            videojuego y la cultura pop con sede en España.{" "}
          </p>
        </div>
        <Spacing lg="30" md="15" />
        <div
          className="container"
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "lighter",
          }}
        >
          <p>
            Como empresa de enfoque multidisciplinar, ofrecemos una visión
            divergente en nuestras soluciones al desarrollo y la promoción de
            experiencias en la industria del gaming y sus sectores asociados,
            creando un nuevo espacio para marcas, empresas e instituciones en su
            interacción con la audiencia.{" "}
          </p>
        </div>
      </section>

      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <AboutStyle5
        variant="cs_type_1"
        thumbnailSrc="/images/digital-agency/about_2.jpeg"
        miniTitle=""
        title="¿QUÉ NOS HACE ÚNICOS?"
        subTitle="El factor diferencial de A1M radica en nuestra capacidad para integrar en cada proyecto una experiencia única y personalizada, redefiniendo la interacción entre las marcas y sus audiencias, tanto en el territorio B2B como B2C, creando experiencias que trascienden las fronteras tradicionales del entretenimiento y abren nuevas posibilidades en el mundo del gaming y la cultura pop."
        progressBarList={[
          { title: "Desarrollo de proyectos tecnológicos", percentage: "95" },
          { title: "Eventos presenciales", percentage: "85" },
          { title: "Gaming", percentage: "100" },
        ]}
        // salesTitle="Sales Increase"
        // groth="25"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="¿CÓMO TRABAJAMOS?" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Personalización"
                  subTitle="En A1M apostamos por mantener una relación cercana, clara y personalizada con nuestros compañeros de viaje, ofreciendo una atención y soporte constantes para asegurar el éxito de cada proyecto. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Enfoque"
                  subTitle="Nuestro enfoque centrado en las personas y nuestra capacidad para adaptarnos rápidamente a los cambios del ecosistema nos posicionan como líderes en el sector del entretenimiento moderno, y nuestros resultados, son nuestro mejor aval. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Marcas"
                  subTitle="Han confiado en nosotros marcas como Intel, google, Amazon, Riot Games, Electronic Arts, Ubisoft, Bandai Namco, MediaMarkt, Movistar e HiperDino, entre otras"
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      {/* OUR TEAM SECTION */}
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            // title="Meet our experts team behind <br />the zivan agency"
            title="NUESTRO EQUIPO"
            variantColor="cs_accent_color"          />
          <Spacing lg="85" md="45" />
        </div>
        <TeamSlider data={teamData} />
      </section> */}
      {/* <Spacing lg="135" md="70" />
      <Marquee text="We Create Design - Build App - Website - Branding - SEO" />
      <Spacing lg="84" md="50" />
      <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div> */}
      {/* <Spacing lg="135" md="80" /> */}
    </>
  );
}
