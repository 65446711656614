import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import ServiceStyle2 from "../Service/ServiceStyle2";
import SectionHeading from "../SectionHeading";
import PricingTableList from "../PricingTable/PricingTableList";
import CtaStyle2 from "../Cta/CtaStyle2";
import { pageTitle } from "../../helpers/PageTitle";
const serviceData = [
  {
    number: "01",
    title: "Eventos presenciales",
    subTitle:
      "Equipo con más de 18 años de experiencia desarrollando eventos tecnológicos, en especial dentro de la industria de los videojuegos. Durante los últimos años, este grupo ha organizado, entre otras cosas, Tenerife GG 2022 y 2023, Gamelab Video Games Summit Tenerife 2022, Gaming Points en tres municipios, las finales de la Liga Canaria de Esports durante cinco temporadas y las finales presenciales de Juegos Escolares Minecraft Edition.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 32 32"
        >
          <path
            fill="#D2D2D2"
            d="M26 14h-2v2h2a3.003 3.003 0 0 1 3 3v4h2v-4a5.006 5.006 0 0 0-5-5M24 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-1 28h-2v-2a3.003 3.003 0 0 0-3-3h-4a3.003 3.003 0 0 0-3 3v2H9v-2a5.006 5.006 0 0 1 5-5h4a5.006 5.006 0 0 1 5 5zm-7-17a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-8 3H6a5.006 5.006 0 0 0-5 5v4h2v-4a3.003 3.003 0 0 1 3-3h2zM8 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5"
          ></path>
        </svg>
      </div>
    ),

    href: "/service/service-details",
  },
  {
    number: "02",
    title: "Producción audiovisual",
    subTitle:
      "Ponemos a tú disposición a un gran equipo multidisciplinar capaz de crear eventos y festivales donde más de 800.000 personas han disfrutado con nosotros.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={180}
          height={180}
          viewBox="0 0 24 24"
        >
          <path
            fill="#D2D2D2"
            d="M6 16h8v-.55q0-1.1-1.1-1.775T10 13t-2.9.675T6 15.45zm4-4q.825 0 1.413-.587T12 10t-.587-1.412T10 8t-1.412.588T8 10t.588 1.413T10 12m-6 8q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h12q.825 0 1.413.588T18 6v4.5l4-4v11l-4-4V18q0 .825-.587 1.413T16 20zm0-2h12V6H4zm0 0V6z"
          ></path>
        </svg>
      </div>
    ),
    href: "/service/service-details",
  },
  {
    number: "03",
    title: "Marketing digital",
    subTitle:
      "Nuestro equipo de marketing está formado por profesionales con dilatada experiencia en el sector del entretenimiento. Creamos un nuevo camino para que tú estrategia encaje perfectamente con nosotros. Desarrollamos tus ideas y las llevamos a cabo.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 16 16"
        >
          <path
            fill="#D2D2D2"
            d="M14.25 2.1a1.25 1.25 0 0 0-1.17-.1L6.91 4.43a1.22 1.22 0 0 1-.46.09H2.5a1.25 1.25 0 0 0-1.25 1.25v.1H0v3h1.25V9a1.25 1.25 0 0 0 1.25 1.22L4 13.4a1.26 1.26 0 0 0 1.13.72h.63A1.25 1.25 0 0 0 7 12.87v-2.53l6.08 2.43a1.27 1.27 0 0 0 .47.09a1.29 1.29 0 0 0 .7-.22a1.25 1.25 0 0 0 .55-1V3.13a1.25 1.25 0 0 0-.55-1.03m-8.5 3.67V9H2.5V5.77zm0 7.1h-.63l-1.23-2.65h1.86zm1.62-3.72A2.29 2.29 0 0 0 7 9V5.7a2.26 2.26 0 0 0 .37-.11l6.18-2.46v8.48zm7.46-3.03v2.5a1.25 1.25 0 0 0 0-2.5"
          ></path>
        </svg>
      </div>
    ),
    href: "/service/service-details",
  },
  {
    number: "04",
    title: "Desarrollo de proyectos tecnológicos",
    subTitle:
      "Diseñamos estrategias y estudios de desarrollo de la industria del videojuego para territorios y entidades. Además, ayudamos a empresas a diseñar un modelo de negocio perfecto para su entrada en el sector poniendo el foco en la innovación y el emprendimiento.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
            <path
              fill="#D2D2D2"
              d="M15 2a1 1 0 0 1 1 1v1h1a3 3 0 0 1 3 3v1h1a1 1 0 1 1 0 2h-1v4h1a1 1 0 1 1 0 2h-1v1a3 3 0 0 1-3 3h-1v1a1 1 0 1 1-2 0v-1h-4v1a1 1 0 1 1-2 0v-1H7a3 3 0 0 1-3-3v-1H3a1 1 0 1 1 0-2h1v-4H3a1 1 0 0 1 0-2h1V7a3 3 0 0 1 3-3h1V3a1 1 0 0 1 2 0v1h4V3a1 1 0 0 1 1-1m2 4H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1m-3 2a2 2 0 0 1 1.995 1.85L16 10v4a2 2 0 0 1-1.85 1.995L14 16h-4a2 2 0 0 1-1.995-1.85L8 14v-4a2 2 0 0 1 1.85-1.995L10 8zm0 2h-4v4h4z"
            ></path>
          </g>
        </svg>
      </div>
    ),
    href: "/service/service-details",
  },
  {
    number: "05",
    title: "Asesoramiento en la industria del videojuego",
    subTitle:
      "A través de la división A1M Consulting contamos con los mejores profesionales a nivel de experiencia, prestigio y cualificación con los que poder acreditar unos servicios de asesoramiento, acompañamiento y dirección de proyectos de máxima calidad.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "15px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={160}
          height={160}
          viewBox="0 0 14 14"
        >
          <g
            fill="none"
            stroke="#D2D2D2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4.4 7v2.31m1.156-1.154H3.245m-1.989-.963l-.412 3.71a2.283 2.283 0 0 0 4.311 1.272l.36-.718h2.97l.36.718a2.283 2.283 0 0 0 4.31-1.273l-.411-3.71a3 3 0 0 0-2.982-2.668H4.238a3 3 0 0 0-2.982 2.669"></path>
            <path d="M7 4.524v-.98a1 1 0 0 1 1-1h1.466a1 1 0 0 0 1-1V.561m0 6.934a.248.248 0 0 1 0-.495m0 .495a.248.248 0 1 0 0-.495M8.733 8.733a.248.248 0 0 1 .495 0m-.495 0a.248.248 0 0 0 .495 0"></path>
          </g>
        </svg>
      </div>
    ),
    href: "/service/service-details",
  },
];

export default function ServicePage() {
  pageTitle("Service");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Mucho más que un juego"
        subTitle="Servicios"
        shape="shape_2"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <ServiceStyle2 data={serviceData} />
      </div>
      {/* OUR PRICING SECTION */}
      {/* <Spacing lg="150" md="80" />
      <section className="cs_gray_bg_2 cs_shape_animation_2">
        <div className="cs_shape_1 position-absolute">
          <svg
            width={57}
            height={41}
            viewBox="0 0 57 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M55.1923 32.7347C52.209 37.0618 42.704 43.3879 40.3199 34.8097C39.0585 30.2359 42.6536 24.5172 43.1266 19.9687C43.8204 13.2821 39.973 5.41874 32.4359 6.05135C31.8886 6.10385 31.3452 6.19047 30.8086 6.31072C29.5308 4.21019 27.6918 2.5107 25.5002 1.40489C23.3085 0.299087 20.8519 -0.168816 18.4087 0.0542238C11.0797 0.743766 5.31489 7.39247 2.40097 13.6616C0.297443 17.9159 -0.0470809 22.831 1.44227 27.3386C2.02253 28.9897 4.52649 28.3381 4.07868 26.6111C2.33789 19.994 6.55742 12.4976 11.1238 7.99978C15.6903 3.50194 23.1517 2.572 26.8919 7.71511C22.3191 9.96086 18.2321 14.1614 15.6019 17.957C12.4483 22.5245 9.15598 31.3746 15.1794 35.2589C21.0135 39.0165 27.7181 36.5241 30.9852 30.8306C34.3217 24.9727 34.2461 16.9828 32.6377 10.6251C32.4379 9.84013 32.1764 9.07227 31.8556 8.32873C34.7948 7.65817 37.6078 8.80319 39.4747 11.7765C42.4517 16.502 40.9317 21.6009 39.5126 26.5289C38.1691 31.1785 36.6617 38.618 42.9185 40.5728C48.4057 42.2935 54.6246 38.6054 56.4853 33.2978C56.7691 32.507 55.6275 32.1085 55.1923 32.7347ZM27.8253 28.0345C26.6396 30.7231 24.5519 32.7537 21.5181 32.9751C16.3336 33.3547 15.3434 29.0909 16.4723 25.0739C18.1122 19.3298 23.2274 13.4845 28.1407 10.0684C28.1664 10.1199 28.1896 10.1727 28.2101 10.2266C30.2749 16.0071 30.1378 22.349 27.8253 28.0345Z"
              fill="#A3A3A3"
            />
          </svg>
        </div>
        <div className="cs_shape_2 position-absolute">
          <svg
            width={21}
            height={24}
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M1.83883 0.992714L20.9086 13.6609L0.402728 23.8417L1.83883 0.992714Z"
              fill="#A3A3A3"
            />
          </svg>
        </div>
        <div className="cs_height_143 cs_height_lg_75" />
        <div className="container">
          <SectionHeading
            title="Offering budget friendly pricing <br /> solutions for your business"
            subTitle="Our Pricing"
          />
          <Spacing lg="85" md="45" />
        </div>
        <div className="container">
          <PricingTableList />
        </div>
        <div className="cs_height_150 cs_height_lg_80" />
      </section> */}

      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="¿Tienes algún proyecto específico en mente? <br />¡Envíanos un mensaje!"
        btnText="Contactar"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
