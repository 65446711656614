import React from "react";
import Hero from "../Hero";
import Spacing from "../Spacing";
import FunFact from "../FunFact";
import About from "../About";
import WhyChose from "../WhyChose";
import Service from "../Service";
import Portfolio from "../Portfolio";
import Button from "../Button";
import SectionHeading from "../SectionHeading";
import Award from "../Award";
import Accordion from "../Accordion";
import Cta from "../Cta";
import TestimonialSlider from "../Slider/TestimonialSlider";
import PostCarousel from "../Slider/PostCarousel";
import { pageTitle } from "../../helpers/PageTitle";

const funfactData = [
  { title: "AÑOS DE EXPERIENCIA", number: "18" },
  { title: "PROYECTOS", number: "+50" },
  { title: "EVENTOS PRESENCIALES", number: "+30" },
  { title: "PERSONAS EN EL EQUIPO", number: "12" },
];

const whyChoseFeatureData = [
  {
    title: "Promovemos la industria gaming y su cultura",
    content:
      "A través de la creación de eventos y la promoción de un ecosistema único, en A1M Entertainment llevamos la industria del gaming y su cultura al siguiente nivel. Diversión garantizada para toda la familia.",
  },
  {
    title: "Actividades, contenidos, proyectos y servicios gaming",
    content:
      "Desde concursos de KPOP, conciertos o competiciones de esports pasando por eventos profesionales o asesoría. Realizamos una gestión 360º donde el protagonista siempre eres tú.",
  },
  {
    title:
      "Relacionamos marcas, empresas e instituciones con los gamers y su industria",
    content:
      "Revolucionamos la experiencia gamer y creamos un caldo de cultivo perfecto donde las marcas, las instituciones públicas y los consumidores se dan la mano.Una visión diferente al desarrollo de experiencias en la industria del videojuego y el entretenimiento.",
  },
];
const serviceListData = [
  {
    title: "Eventos presenciales",
    subtitle:
      "Equipo con más de 18 años de experiencia desarrollando eventos tecnológicos, en especial dentro de la industria de los videojuegos. Durante los últimos años, este grupo ha organizado, entre otras cosas, Tenerife GG 2022 y 2023, Gamelab Video Games Summit Tenerife 2022, Gaming Points en tres municipios, las finales de la Liga Canaria de Esports durante cinco temporadas y las finales presenciales de Juegos Escolares Minecraft Edition.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 32 32"
        >
          <path
            fill="#D2D2D2"
            d="M26 14h-2v2h2a3.003 3.003 0 0 1 3 3v4h2v-4a5.006 5.006 0 0 0-5-5M24 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-1 28h-2v-2a3.003 3.003 0 0 0-3-3h-4a3.003 3.003 0 0 0-3 3v2H9v-2a5.006 5.006 0 0 1 5-5h4a5.006 5.006 0 0 1 5 5zm-7-17a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-8 3H6a5.006 5.006 0 0 0-5 5v4h2v-4a3.003 3.003 0 0 1 3-3h2zM8 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5"
          ></path>
        </svg>
      </div>
    ),
    href: "/services/",
  },
  {
    title: "Producción audiovisual",
    subtitle:
      "Ponemos a tú disposición a un gran equipo multidisciplinar capaz de crear eventos y festivales donde más de 800.000 personas han disfrutado con nosotros.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 24 24"
        >
          <path
            fill="#D2D2D2"
            d="M6 16h8v-.55q0-1.1-1.1-1.775T10 13t-2.9.675T6 15.45zm4-4q.825 0 1.413-.587T12 10t-.587-1.412T10 8t-1.412.588T8 10t.588 1.413T10 12m-6 8q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h12q.825 0 1.413.588T18 6v4.5l4-4v11l-4-4V18q0 .825-.587 1.413T16 20zm0-2h12V6H4zm0 0V6z"
          ></path>
        </svg>
      </div>
    ),
    href: "/services/",
  },
  {
    title: "Marketing digital",
    subtitle:
      "Nuestro equipo de marketing está formado por profesionales con dilatada experiencia en el sector del entretenimiento. Creamos un nuevo camino para que tú estrategia encaje perfectamente con nosotros. Desarrollamos tus ideas y las llevamos a cabo.",
    icon: (
      <div
        style={{ textAlign: "center", paddingTop: "25px", marginRight: "10px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={140}
          height={140}
          viewBox="0 0 16 16"
        >
          <path
            fill="#D2D2D2"
            d="M14.25 2.1a1.25 1.25 0 0 0-1.17-.1L6.91 4.43a1.22 1.22 0 0 1-.46.09H2.5a1.25 1.25 0 0 0-1.25 1.25v.1H0v3h1.25V9a1.25 1.25 0 0 0 1.25 1.22L4 13.4a1.26 1.26 0 0 0 1.13.72h.63A1.25 1.25 0 0 0 7 12.87v-2.53l6.08 2.43a1.27 1.27 0 0 0 .47.09a1.29 1.29 0 0 0 .7-.22a1.25 1.25 0 0 0 .55-1V3.13a1.25 1.25 0 0 0-.55-1.03m-8.5 3.67V9H2.5V5.77zm0 7.1h-.63l-1.23-2.65h1.86zm1.62-3.72A2.29 2.29 0 0 0 7 9V5.7a2.26 2.26 0 0 0 .37-.11l6.18-2.46v8.48zm7.46-3.03v2.5a1.25 1.25 0 0 0 0-2.5"
          ></path>
        </svg>
      </div>
    ),
    href: "/services/",
  },
  {
    title: "Desarrollo de proyectos tecnológicos",
    subtitle:
      "Diseñamos estrategias y estudios de desarrollo de la industria del videojuego para territorios y entidades. Además, ayudamos a empresas a diseñar un modelo de negocio perfecto para su entrada en el sector poniendo el foco en la innovación y el emprendimiento.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
            <path
              fill="#D2D2D2"
              d="M15 2a1 1 0 0 1 1 1v1h1a3 3 0 0 1 3 3v1h1a1 1 0 1 1 0 2h-1v4h1a1 1 0 1 1 0 2h-1v1a3 3 0 0 1-3 3h-1v1a1 1 0 1 1-2 0v-1h-4v1a1 1 0 1 1-2 0v-1H7a3 3 0 0 1-3-3v-1H3a1 1 0 1 1 0-2h1v-4H3a1 1 0 0 1 0-2h1V7a3 3 0 0 1 3-3h1V3a1 1 0 0 1 2 0v1h4V3a1 1 0 0 1 1-1m2 4H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1m-3 2a2 2 0 0 1 1.995 1.85L16 10v4a2 2 0 0 1-1.85 1.995L14 16h-4a2 2 0 0 1-1.995-1.85L8 14v-4a2 2 0 0 1 1.85-1.995L10 8zm0 2h-4v4h4z"
            ></path>
          </g>
        </svg>
      </div>
    ),
    href: "/services/",
  },
];
const portfolioData = [
  {
    href: "/portfolio/liga-canaria-de-esports",
    imgUrl: "/images/portfolio/miniatura-lces.jpg",
    title: "Liga Canaria de Esports",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/juegos-escolares-minecraft",
    imgUrl: "/images/portfolio/jeme.jpg",
    title: "Juegos Escolares Minecraft Education",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/tenerifegg",
    imgUrl: "/images/creative-agency/portfolio_2.jpeg",
    title: "Tenerife GG",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/gamelab-tenerife",
    imgUrl: "/images/creative-agency/portfolio_2.jpeg",
    title: "Gamelab Video Games Summit Tenerife",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/gaming-points",
    imgUrl: "/images/creative-agency/portfolio_2.jpeg",
    title: "Gaming Points",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/simseries",
    imgUrl: "/images/creative-agency/portfolio_2.jpeg",
    title: "Simseries",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/siam-mall-chess-weekend",
    imgUrl: "/images/creative-agency/portfolio_2.jpeg",
    title: "Siam Mall Chess Weekend",
    btnText: "Ver proyecto",
  },
];
// const awardData = [
//   {
//     brand: 'Behance',
//     title: 'UI/UX design of the month',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'December 12, 2023',
//     awardImgUrl: '/images/creative-agency/award_img_1.svg',
//   },
//   {
//     brand: 'Awwwards',
//     title: 'CSS awards design',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'January 05, 2022',
//     awardImgUrl: '/images/creative-agency/award_img_2.svg',
//   },
//   {
//     brand: 'Google',
//     title: 'Website of the day',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'March 20, 2021',
//     awardImgUrl: '/images/creative-agency/award_img_3.svg',
//   },
// ];
const testimonialData = [
  {
    text: "Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Ansari Patron",
    avatarDesignation: "CEO at Delta",
  },
  {
    text: "Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Jhon Doe",
    avatarDesignation: "Manager at Delta",
  },
  {
    text: "Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Ramatam Coo",
    avatarDesignation: "MD at Delta",
  },
];
// const faqData = [
//   {
//     title: '01. I need your services and how can i contact you throw email?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '02. What are the different types of service we provide?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '03. What are the different stages of the working process?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '04. What is the difference between direct and digital marketing?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '05. How can i payment proceed after complete project?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
// ];

const postData = [
  {
    thumbnailSrc: "/images/creative-agency/post_1.jpeg",
    title: "Hoy nace A1M",
    date: "07 Mar 2023",
    url: "/blog/hoy-nace-a1m",
  },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_2.jpeg',
  //   title: 'Artistic mind will be great for creation anything',
  //   date: '22 Apr 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_3.jpeg',
  //   title: 'AI will take over all job for human within few years',
  //   date: '13 May 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_4.jpeg',
  //   title: 'Your agency need to replace some artistic mind people',
  //   date: '15 Mar 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_1.jpeg',
  //   title: 'How to keep fear from ruining your art business with confident',
  //   date: '07 Mar 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_2.jpeg',
  //   title: 'Artistic mind will be great for creation anything',
  //   date: '22 Apr 2023',
  //   url: '/portfolio/portfolio-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_3.jpeg',
  //   title: 'AI will take over all job for human within few years',
  //   date: '13 May 2023',
  //   url: '/portfolio/portfolio-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_4.jpeg',
  //   title: 'Your agency need to replace some artistic mind people',
  //   date: '15 Mar 2023',
  //   url: '/portfolio/portfolio-details',
  // },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title={[
          "Eventos presenciales",
          "Competiciones online",
          "Marketing digital",
          "Producción audiovisual",
          "Desarrollo de proyectos tecnológicos",
          "Plataforma gaming propia",
          "I+D+i",
          "Tecnología puntera",
          "Metodologías ágiles de desarrollo de software",
        ]}
        subtitle="CONNECTING CULTURES, EMOTIONS & WORLDS"
        // videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
        imgUrl="/images/home/home-hero.jpg"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <About
        thumbnail="/images/home/que-es-a1m.jpg"
        title="¿QUÉ ES A1M?"
        // title="Full-stack creatives and designing agency"
        subTitle="A1M es una empresa de base tecnológica que opera dentro del sector de los videojuegos. Un equipo multidisciplinar con alcance global con más de 20 años de experiencia en la industria del gaming"
        featureList={[
          "Eventos presenciales",
          "Producción audiovisual",
          "Desarrollo de proyectos tecnológicos",
        ]}
        btnText="Saber más"
        btnUrl="/about"
      />
      <Spacing lg="185" md="75" />
      <WhyChose
        // sectionTitle="We have depth of market knowledge"
        sectionTitle="¿QUÉ HACEMOS?"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/home/que-hacemos.png"
      />
      <Spacing lg="150" md="80" />

      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="SERVICIOS"
            // subTitle="Servicios"
            variantColor="cs_accent_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Nuestros servicios principales"
            sectionSubTitle="Servicios"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="PORTFOLIO"
            subTitle=""
            variantColor="cs_accent_color"
          />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText="Ver todos los proyectos" btnUrl="/portfolio" />
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

      {/* AWARDS SECTION */}

      {/* <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#A3A3A3"
              />
            </g>
          </svg>
        </div>
  
        <div className="container">
          <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div> 
        <Spacing lg="150" md="80" />
     </section> */}
      {/* <TestimonialSlider
        layeredImages={[
          '/images/creative-agency/layer_img_1.jpeg',
          '/images/creative-agency/layer_img_2.jpeg',
          '/images/creative-agency/layer_img_3.jpeg',
          '/images/creative-agency/layer_img_4.jpeg',
          '/images/creative-agency/layer_img_5.jpeg',
        ]}
        data={testimonialData}
      /> */}
      <section>
        <div className="container">
          <Cta
            title="¿Tienes algún proyecto en mente?"
            btnText="Contáctanos"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="BLOG"
            subTitle=""
            variantColor="cs_accent_color"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
        <Spacing lg="120" md="50" />
      </section>

      {/* FAQ SECTION */}
      {/* <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section> */}
    </>
  );
}
