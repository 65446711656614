import React from "react";
import { Helmet } from "react-helmet";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import image from "./jeme-detalles.jpg";

const title = "Liga Canaria de Esports";
const description =
  "Nacida en 2017, LCES es la primera competición de videojuegos competitivos de ámbito autonómico y regional establecida en España, con una estructura y formato accesible a todos los jugadores del Archipiélago.";

export default function PortfolioLces() {
  pageTitle(title);
  return (
    <>
      <Helmet>
        {/*  OG metadata */}

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}
      </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3 title={title} subTitle="Detalles del proyecto" />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>
              <p style={{ fontWeight: 100 }}>
                Nacida en 2017, LCES es la primera competición de videojuegos
                competitivos de ámbito autonómico y regional establecida en
                España, con una estructura y formato accesible a todos los
                jugadores del Archipiélago, independientemente de su nivel de
                habilidad.
              </p>
              <p style={{ fontWeight: 100 }}>
                La temporada piloto fue organizada por la empresa Gaming
                Experience, con el patrocinio del retailer local Última
                Informática. Tras el éxito obtenido en el test inicial, en 2018,
                HiperDino realiza una apuesta pionera y se convierte en el
                patrocinador principal de la primera liga autonómica de deportes
                electrónicos en todo el país, conocida como Liga Canaria de
                Esports HiperDino.​ Esta primera temporada fue un éxito rotundo
                y contó con más de 5.000 jugadores canarios registrados.
              </p>
              <p style={{ fontWeight: 100 }}>
                En 2019, la Liga Canaria de Esports HiperDino se convierte en
                producto propio tras ser adquirido por el Grupo DinoSol y se
                constituye la empresa Liga Canaria de Esports SL. Este mismo año
                se lanza la segunda temporada, que volverá a ser un éxito
                duplicando la cifra de usuarios registrados, con hasta 11.000
                jugadores activos.
              </p>
              <p style={{ fontWeight: 100 }}>
                Durante los años siguientes y hasta finales de 2023, el proyecto
                sigue aumentando, organizando hasta 300 actividades​​ por
                temporada y ofreciendo por primera vez una producción
                audiovisual profesional a nivel de estudio, contando con un
                grupo de prescriptores entre los que se figuraban el cómico
                canario Kike Pérez, la periodista especializada en gaming Karen
                Hernández o el exjugador profesional de esports Yeray ‘DriD’
                González. Además, durante este periodo se lanzó Tenderete City,
                un servidor de roleplay en el videojuego Grand Theft Auto,
                siendo el mayor contenido transmedia de Canaria y en el que se
                involucraron personalidades como el cantante Dasoul y los
                cómicos Aarón Gómez, David Sainz y Kike Pérez, entre otros.
                También se ofrecieron actividades de formación en la industria,
                destacando la charla “Referentes femeninos y su experiencia en
                la industria de los videojuegos” celebrada durante el día de la
                mujer.
              </p>
              <p style={{ fontWeight: 100 }}>
                Además, tras el periodo de pandemia, vuelven a cobrar
                importancia los eventos presenciales, en los que LCES se
                convierte en una de las productoras destacadas de las Islas
                Canarias. Además de algunos eventos municipales, la empresa
                organiza la Tenerife GG durante los años 2022 y 2023, un gran
                evento presencial multidisciplinar enfocado al gaming, la
                tecnología y cultura geek.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
