import React from "react";
import { Helmet } from "react-helmet";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import image from "./jeme-detalles.jpg";

const title = "Gamelab Video Games Summit Tenerife";
const description = "";

export default function PortfolioGamelab() {
  pageTitle(title);
  return (
    <>
      <Helmet>
        {/*  OG metadata */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}
      </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3 title={title} subTitle="Detalles del proyecto" />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>Recuperación del famoso foro de videojuegos&nbsp;</span>
                  <i>
                    <span>Gamelab</span>
                  </i>
                  <span>
                    , que se celebraba anualmente en Barcelona hasta 2019.
                    Gamelab Video Games Summit Tenerife reunió a profesionales
                    de la industria de los videojuegos, incluyendo
                    desarrolladores, diseñadores, productores y otros creativos,
                    así como a empresas y medios de comunicación del sector, en
                    un evento con un formato innovador.
                  </span>
                </span>
              </p>
              <p style={{ fontWeight: 100 }}>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Del 25 al 28 de noviembre de 2022, y de manera privada en
                    las instalaciones del Hotel Gran Meliá Palacio de Isora, más
                    de 70 altos directivos de empresas de 17 países, líderes del
                    sector y un gran panel de expertos, se reunieron para
                    debatir sobre el futuro del sector. Por otro lado y
                    completamente accesible a todo el público, durante el día
                    28, el Auditorio de Tenerife Adán Martín acogió un gran foro
                    donde 600 personas, entre profesionales y aficionados, se
                    dieron cita para escuchar a los grandes líderes de la
                    industria, en las diferentes conferencias, entrevistas y
                    mesas redondas; destacándose las figuras de John Romero, el
                    galardonado cofundador de id Software y Romero Games
                    (responsable de algunos de los mayores videojuegos de
                    disparos en primera persona como Doom, Quake y Wolfenstein),
                    Ian Livingstone, cofundador y partner de Hiro Capital,
                    considerado uno de los padres fundadores de la industria del
                    videojuego en Reino Unido, y Simay Dinç, cofundadora de
                    Recontact Games y Women in Games Turkey.
                  </span>
                </span>
              </p>
              <p style={{ fontWeight: 100 }}>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Este evento, además de reunir a los referentes del sector,
                    sirvió para resaltar las bondades que Tenerife y las Islas
                    Canarias pueden ofrecer a la industria del desarrollo del
                    videojuego.
                  </span>
                </span>
              </p>
            </div>
          </div>
          {/* <Spacing lg="115" md="60" /> */}
          {/* <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/others/portfolio_details_2.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_3.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_4.jpeg" alt="Thumb" />
            </div>
          </div> */}
        </div>
        {/* <Spacing lg="90" md="60" /> */}
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
