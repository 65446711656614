import React, { useRef, useState } from "react";
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';
import emailjs from "@emailjs/browser";

export default function ContactPage() {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        "service_stvtjty",
        "template_p9q3nah",
        form.current,
        "fVZqWsSbvzpraIV-W"
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          console.log(result.text);
          alert("El formulario de contacto se ha enviado con éxito.");
          setSendingMail(false);
        },
        (error) => {
          console.log(error.text);
          setSendingMail(false);
        }
      );
  };

  pageTitle('Contacto');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Contáctanos para más información"
        subTitle="Contacto"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="column align-items-center cs_gap_y_45" style={{display: "grid"}}>

            <div className="col-lg-10" style={{alignSelf: "center", justifySelf: "center", marginBottom: "20px"}}>
                <div className="cs_gray_bg_3 cs_contact_form_bg"  />
                <form 
                  id="contact-form"
                  className="cs_contact_form row"
                  method="post"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <div className="col-md-6">
                  <label htmlFor="name" className="cs_fs_21 cs_semibold cs_primary_color form-label">
                    Nombre completo
                  </label>
                  <input
                    placeholder="Escribe tu nombre"
                    type="text"
                    className="cs_form_field"
                    id="name"
                    name="user_name"
                    required
                  />
                  </div>
                  <div className="col-md-6">
                  <label className="cs_fs_21 cs_semibold cs_primary_color form-label" htmlFor="email" >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    placeholder="Escribe tu email"
                    type="email"
                    className="cs_form_field"
                    required
                  />
                  </div>
                  <div className="cs_height_38 cs_height_lg_25" />
                  <div className="col-md-6">
                  <label className="cs_fs_21 cs_semibold cs_primary_color form-label" htmlFor="form-phone">
                    Teléfono
                  </label>
                  <input
                    placeholder="Escribe tu teléfono"
                    type="number"
                    className="cs_form_field"
                    id="form-phone"
                    name="user_phone"
                    required
                  />
                  </div>
                  {/* <div className="cs_height_38 cs_height_lg_25" /> */}
                  <div className="col-md-6">
                  <label className="cs_fs_21 cs_semibold cs_primary_color form-label" htmlFor="form-organization">
                    Organización
                  </label>
                  <input
                    placeholder="Escribe tu organización"
                    type="text"
                    className="cs_form_field"
                    id="form-organization"
                    name="organization"
                    required
                  />
                  </div>
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color form-label" htmlFor="form-message">
                    Mensaje
                  </label>

                  <textarea 
                    cols={30} 
                    rows={5} 
                    className="cs_form_field"  
                    id="form-message"          
                    required
                    defaultValue={""}
                    name="message"
                  />
                  
                  <div className="cs_height_38 cs_height_lg_25" />
                  <div className="form-check">
                    <input 
                      type="checkbox" 
                      className="form-check-input" 
                      id="policy-checkbox" 
                      required
                    />
                    <label className="form-check-label" htmlFor="policy-checkbox" style={{fontWeight: "bold"}}>
                      He entendido y acepto la Política de Protección de datos que he podido leer en (1).
                    </label>
                  </div>
                  <p id="policy-section" style={{fontSize: "12px", textAlign: "justify", marginTop: "10px"}}>
                    (1) Al marcar la casilla, usted está aceptando la política de protección de datos.
                    Le informamos que los datos aportados en el formulario, así como su dirección de correo electrónico, pasarán a formar parte de la base de datos de A1m Gamesports, S.L.
                    La finalidad es para la gestión interna, profesional, comercial y su fidelización, así como dar respuesta a la/s consulta/s planteadas, así como para el envío de información sobre nuestra actividad.
                    Su cuenta de correo electrónico, se mantendrán de forma indefinida en tanto no solicite su supresión.
                    Si usted no desea recibir más información, más adelante, sobre nuestros productos o servicios, puede darse de baja en la siguiente dirección de correo electrónico a1m@protecciondedatos.info, indicando en el asunto "baja" o "no enviar correos", o siguiendo las instrucciones a pie del correo electrónico recibido o en el formato recibido.
                    Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web: <a href="http://a1m.protecciondedatos.info">http://a1m.protecciondedatos.info</a>
                  </p>
                  <div className="cs_height_20 cs_height_lg_25" />
                  <div style={{textAlignLast: "center"}}>
                  <button
                    id="submit-btn"
                    className="cs_btn cs_style_1 col-md-6"
                    >
                    {sendingMail ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                          ></span>
                        Enviando ...
                      </>
                    ) : (
                      <>
                        Enviar{' '}
                        <span>
                          <i>
                            <Icon icon="fa6-solid:arrow-right" />
                          </i>
                          <i>
                            <Icon icon="fa6-solid:arrow-right" />
                          </i>
                        </span>
                      </>
                    )}
                  </button>
                    </div>
                </form>
              </div>
              <div className="col-lg-12" style={{textAlign: "center", marginBottom: "40px"}}>
              <h2 className="cs_fs_50">
                Nuestras oficinas
              </h2>
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  {/* mailto */}
                  <p className="mb-0" style={{fontWeight: 100}}>info @ a1m.es</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Teléfono:</h3>
                  <p className="mb-0" style={{fontWeight: 100}}>+34 822 903 529</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Dirección:</h3>
                  <p className="mb-0" style={{fontWeight: 100}}>Calle Subida al Mayorazgo, 13, 38110, Santa Cruz de Tenerife.</p>
                </li>
              </ul>
            </div>
            </div>
          </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
      <div className="cs_map">
        <iframe
          id="map"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.119058596773!2d-16.285175523763094!3d28.44582717576722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc41ccc23a781985%3A0xec9958b15a8c6e27!2sC.%20Subida%20al%20Mayorazgo%2C%2013%2C%2038110%20Santa%20Cruz%20de%20Tenerife!5e0!3m2!1ses!2ses!4v1718617776948!5m2!1ses!2ses"
          title="Google Map"
          referrerPolicy="no-referrer"
        />
      </div>
    </>
  );
}
