import React from 'react';
import logo from './a1m_landing_logo_claim.png'

export default function Lading() {
  return (
    <div className='landing_background'>
      <div className='landing_container'>
      <img className='landing_logo' src={logo} />
      {/* <div className='landing_text'>
      <span >Connecting: <span className=''>gaming y los esports</span> <br/>a tu servicio</span>
      </div> */}
      {/*<div style={{width:"560px",margin:"auto", maxWidth:"70vw",textAlign:"left", color:"black", fontWeight:"normal", marginTop:"50px"}}>
        <span>Eventos presenciales, competiciones online, marketing digital, producción audiovisual, Desarrollo de proyectos tecnológicos, plataforma gaming propia, I+D+i, tecnología puntera, metodologías agiles de desarrollo de software</span>
      </div>*/}
    {/* Botón con mailto */}
    <div style={{marginTop: "50px", margin:"auto"}}>
    <button className='landing_button' onClick={()=>window.location.href="mailto:admin@a1m.es"}>CONTACTA CON NOSOTROS</button>
    </div></div></div>
  );
}