import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import MarketingAgencyPage from "./components/Pages/MarketingAgencyPage";
import StudioAgencyPage from "./components/Pages/StudioAgencyPage";
import DigitalAgencyPage from "./components/Pages/DigitalAgencyPage";
import ServicePage from "./components/Pages/ServicePage";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogListPage from "./components/Pages/BlogListPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
import TeamPage from "./components/Pages/TeamPage";
import TeamDetailsPage from "./components/Pages/TeamDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import Layout2 from "./components/Layout/Layout2";
import TechStartupPage from "./components/Pages/TechStartupPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import Shop from "./components/Pages/Shop";
import ProductDetails from "./components/Pages/Shop/ProductDetails";
import Cart from "./components/Pages/Shop/Cart";
import Checkout from "./components/Pages/Shop/Checkout";
import Success from "./components/Pages/Shop/Success";
import Wishlist from "./components/Pages/Shop/Wishlist";
import Layout3 from "./components/Layout/Layout3";
import ErrorPage from "./components/Pages/ErrorPage";
import PortfolioJeme from "./components/Portfolio/PorfolioJeme";
import BlogPost1 from "./components/Blog/BlogPost1";
import Legal from "./components/Legal/Legal";
import Privacy from "./components/Legal/Privacy";
import Cookies from "./components/Legal/Cookies";
import PortfolioTgg from "./components/Portfolio/PortfolioTgg";
import PortfolioGamelab from "./components/Portfolio/PortfolioGamelab";
import PortFolioGamingPoints from "./components/Portfolio/PortfolioGamingPoints";
import PortfolioLces from "./components/Portfolio/PortfolioLces";
import PortfolioSimseries from "./components/Portfolio/PortfolioSimseries";
import PortfolioSiamMallChess from "./components/Portfolio/PorfolioSiamMallChess";
import Landing from "./components/Landing";

const keywords = [
  "gaming",
  "esports",
  "juegos",
  "videojuegos",
  "lanparty",
  "lan",
  "kpop",
  "tenerife",
  "tenerife gg",
  "tenerifegg",
  "cosplay",
  "tlp",
  "tenerife lan party",
  "kpop",
  "juegos de mesa",
  "tgg",
  "formacion",
  "ciencia",
  "esports business live",
  "indie",
  "tlp tenerife",
  "gamecon",
  "hobbygg",
  "stan fest",
  "gamelab",
  "Tenerife video games summit",
  "A1M",
  "A1M Gamesports",
];

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Fjalla+One&family=Inter:wght@100..900&display=swap"
        rel="stylesheet"
      />

      <Helmet>
        {/*  OG metadata */}

        <meta property="og:title" content="A1M" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta
          property="og:image"
          content={`${window.location.href}images/opengraph.png`}
        />
        <meta
          property="og:description"
          content="A1M es una empresa de base tecnológica que opera dentro del sector de los videojuegos. Un equipo multidisciplinar con alcance global con más 20 años de experiencia en la industria del gaming."
        />
        <meta property="og:site_name" content="A1M" />
        <meta property="og:locale" content="es_ES" />
        <meta name="keywords" content={keywords.join(", ")} />

        <meta name="twitter:title" content="A1M" />
        <meta
          name="twitter:description"
          content="A1M es una empresa de base tecnológica que opera dentro del sector de los videojuegos. Un equipo multidisciplinar con alcance global con más de 20 años de experiencia en la industria del gaming."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={`${window.location.href}images/opengraph.png`}
        />
        {/*  END OG metadata */}
      </Helmet>
      <Routes>
        {process.env.REACT_APP_LANDING === "true" &&
        window.localStorage.getItem("skip_cs") !== "true" ? (
          <Route path="/" element={<Landing />}>
            {" "}
          </Route>
        ) : (
          <>
            <Route path="/" element={<Layout darkMode />}>
              {/* <Route
          path="marketing-agency"
          element={<MarketingAgencyPage darkMode />}
        /> */}
              {/* <Route path="studio-agency" element={<StudioAgencyPage darkMode />} /> */}
              {/* <Route path="digital-agency" element={<DigitalAgencyPage darkMode />} /> */}
              <Route path="about" element={<AboutPage darkMode />} />
              <Route path="services" element={<ServicePage />} />
              <Route path="legal" element={<Legal />} />
              <Route path="privacidad" element={<Privacy />} />
              <Route path="cookies" element={<Cookies />} />
              {/* <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        /> */}
              <Route path="blog" element={<BlogPage />} />
              {/* <Route path="blog-list" element={<BlogListPage />} /> */}
              <Route path="blog/hoy-nace-a1m" element={<BlogPost1 />} />
              <Route path="portfolio" element={<PortfolioPage />} />
              <Route
                path="portfolio/juegos-escolares-minecraft"
                element={<PortfolioJeme />}
              />
              <Route path="portfolio/tenerifegg" element={<PortfolioTgg />} />
              <Route
                path="portfolio/gamelab-tenerife"
                element={<PortfolioGamelab />}
              />
              <Route
                path="portfolio/gaming-points"
                element={<PortFolioGamingPoints />}
              />
              <Route
                path="portfolio/liga-canaria-de-esports"
                element={<PortfolioLces />}
              />
              <Route
                path="portfolio/simseries"
                element={<PortfolioSimseries />}
              />
              <Route
                path="portfolio/siam-mall-chess-weekend"
                element={<PortfolioSiamMallChess />}
              />
              {/* <Route path="case-study-details" element={<CaseStudyDetailsPage />} /> */}
              {/* <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} /> */}
              <Route path="contact" element={<ContactPage />} />
            </Route>
            <Route path="/" element={<Layout2 darkMode />}>
              <Route index element={<Home />} />
              {/* <Route path="tech-startup" element={<TechStartupPage />} /> */}
            </Route>
            <Route path="/" element={<Layout3 darkMode />}>
              {/* <Route path="shop" element={<Shop />} />
        <Route path="shop/:productId" element={<ProductDetails />} />
        <Route path="shop/cart" element={<Cart />} />
        <Route path="shop/checkout" element={<Checkout />} />
        <Route path="shop/success" element={<Success />} />
        <Route path="shop/wishlist" element={<Wishlist />} /> */}
            </Route>
            {/* Start Light Mode */}
            <Route path="/light/" element={<Layout />}>
              {/* <Route path="marketing-agency" element={<MarketingAgencyPage />} />
        <Route path="studio-agency" element={<StudioAgencyPage />} />
        <Route path="digital-agency" element={<DigitalAgencyPage />} /> */}
              <Route path="about" element={<AboutPage />} />
              <Route path="service" element={<ServicePage />} />
              {/* <Route
          path="service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        /> */}
              <Route path="blog" element={<BlogPage />} />
              {/* <Route path="blog-list" element={<BlogListPage />} /> */}
              {/* <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} /> */}
              <Route path="portfolio" element={<PortfolioPage />} />
              {/* <Route
          path="portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        /> */}
              {/* <Route path="case-study-details" element={<CaseStudyDetailsPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} /> */}
              <Route path="contact" element={<ContactPage />} />
            </Route>
            <Route path="/light/" element={<Layout2 />}>
              <Route index element={<Home />} />
              {/* <Route path="tech-startup" element={<TechStartupPage />} /> */}
            </Route>
            {/* <Route path="/light/" element={<Layout3 />}>
        <Route path="shop" element={<Shop />} />
        <Route path="shop/:productId" element={<ProductDetails />} />
        <Route path="shop/cart" element={<Cart />} />
        <Route path="shop/checkout" element={<Checkout />} />
        <Route path="shop/success" element={<Success />} />
        <Route path="shop/wishlist" element={<Wishlist />} />
      </Route> */}
            {/* End Light Mode */}
            <Route path="*" element={<ErrorPage />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
