import React from 'react';
import { Helmet } from 'react-helmet';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import image from './jeme-detalles.jpg';


const title = 'Simseries';
const description = '';

export default function PortfolioSimseries() {
  pageTitle(title);
  return (
    <>
    <Helmet>

        {/*  OG metadata */}
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={description}
        />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={description}
        />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}

    </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title={title}
        subTitle="Detalles del proyecto"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>
             
              <p style={{fontWeight: 100}} >
                <span><span>Comunidad y plataforma dedicada a la organización y gestión de competiciones de esports centrada en los simuladores de carreras. La plataforma permite a los usuarios participar en torneos y campeonatos de simulación de carreras, proporcionando una experiencia competitiva y profesional para los aficionados a los deportes electrónicos y a las carreras virtuales.</span></span>
              </p>
              <p style={{fontWeight: 100}} >
                  &nbsp;
              </p>
              <p style={{fontWeight: 100}} >
                  <span><span>Simseries ofrece una variedad de características y servicios, que incluyen:</span></span>
              </p>
              <ul>
                  <li style={{fontWeight: 100}}>
                      <span><span><strong>Inscripción en competiciones.</strong></span><span> Tanto de pago como de libre acceso, Simseries poner a disposición de los usuarios una gran cantidad de torneos, en diferentes formatos y con gran variedad de títulos.</span></span>
                  </li>
                  <li style={{fontWeight: 100}}>
                      <span><span><strong>Resultados y clasificaciones.</strong></span><span> Seguimiento de los resultados de carreras, estadísticas de jugadores y clasificaciones de campeonatos.</span></span>
                  </li>
                  <li style={{fontWeight: 100}}>
                      <span><span><strong>Comunidad.</strong></span><span> Espacios de interacción para los participantes, como Discord y redes sociales, donde pueden compartir experiencias y conectarse con otros entusiastas del motor virtual.</span></span>
                  </li>
                  <li style={{fontWeight: 100}}>
                      <span><span><strong>Contenidos.</strong></span><span> Tanto en formato escrito como audiovisual, para darle mayor protagonismo a los pilotos y así mejorar su experiencia.</span></span>
                  </li>
              </ul>

            </div>
          </div>
          {/* <Spacing lg="115" md="60" /> */}
          {/* <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/others/portfolio_details_2.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_3.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_4.jpeg" alt="Thumb" />
            </div>
          </div> */}
        </div>
        {/* <Spacing lg="90" md="60" /> */}
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
