import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper/modules';
import Post from '../Post';

export default function PostCarousel({ data }) {
  return (
    < >
      <Swiper
        slidesPerView={1}
        spaceBetween={24}
        speed={800}
        centeredSlides
        // pagination={{
        //   clickable: true,
        // }}
        // modules={[Pagination]}
        breakpoints={{
          500: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          991: {
            slidesPerView: 3,
          },
          1750: {
            slidesPerView: 4,
          },
        }}
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <Post {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
